import classes from "./EditUser.module.css";
import Xicon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { setIsEditUserModalShown } from "../../../redux/globalFnSlice";
import { updateUser } from "../../../redux/users/updateUser";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import translations from "../../../en.json";
import SearchableDropdown from "../../searchableDropdown/SearchableDropdown";
import { fetchDepartments } from "../../../redux/department/getDepartments";
import useEnterKey from "../../../helperFunctions/customHooks/useEnterKeySubmit";

export function EditUser() {
  const { selectedData } = useSelector((state) => state.global);
  const { status } = useSelector((state) => state.updateUser);
  const departmentsState = useSelector((state) => state.getDepartments);

  const [firstname, setFirstname] = useState(selectedData?.u_firstname);
  const [lastname, setLastname] = useState(selectedData?.u_lastname);
  const [newDepartmentId, setNewDepartmentId] = useState(selectedData.u_did);
  const dispatch = useDispatch();
  const modalRef = useRef();

  function toggleModal() {
    dispatch(setIsEditUserModalShown());
  }

  useEffect(() => {
    dispatch(fetchDepartments({ company_id: selectedData.d_cid }));
  }, [selectedData]);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(
      updateUser({
        u_id: selectedData.u_id,
        u_firstname: firstname,
        u_lastname: lastname,
        u_did: newDepartmentId,
        u_utid: selectedData.u_utid,
      })
    );
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = status === "loading";

  const modalText = translations.editUserModal;

  // Function for submitting the form when presing the enter key on the keyboard.
  useEnterKey(handleSubmit);

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{modalText.headerText}</h3>
        <span>
          <img
            src={Xicon}
            alt={modalText.closeModalIconAlt}
            onClick={toggleModal}
          />
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formElement}>
          <label htmlFor="firstname">{modalText.firstnameLabel}</label>
          <input
            type="text"
            id="firstname"
            required
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className={classes.formElement}>
          <label htmlFor="lastname">{modalText.lastnameLabel}</label>
          <input
            type="text"
            id="lastname"
            required
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>

        <div className={classes.formElement}>
          <SearchableDropdown
            idForLabel={modalText.selectDepartment}
            options={
              departmentsState.department_names &&
              departmentsState.department_names.map((department) => ({
                value: department.d_id,
                label: department.d_name,
                departmentID: department.d_id,
              }))
            }
            placeholder={modalText.selectName}
            onSelect={(value) => setNewDepartmentId(value)}
            value={newDepartmentId}
          />
        </div>

        <button
          type="submit"
          className={classes.saveBtn}
          disabled={isStatusLoading}
        >
          {isStatusLoading ? <LoadingSpinner /> : modalText.saveBtn}
        </button>
      </form>
    </div>
  );
}
