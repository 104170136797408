import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialGlobalState";

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsOrganizationsClicked: (state) => {
      state.isOrganizationsClicked = true;
      state.isUsersClicked = false;
    },
    setIsUsersClicked: (state) => {
      state.isOrganizationsClicked = false;
      state.isUsersClicked = true;
    },
    setIsAddOrgModalShown: (state, action) => {
      state.isAddOrgModalShown = !state.isAddOrgModalShown;
      state.selectedData = action.payload;
    },
    setIsModalsBgShown: (state, action) => {
      state.isModalsBgShown = action.payload;
    },
    setIsSuccessMsgShown: (state, action) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.successMsg = action.payload;
      state.isSuccessMsgShown = !state.isSuccessMsgShown;
    },
    setIsErrorMsgShown: (state, action) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.errorMsg = action.payload;
      state.isErrorMsgShown = !state.isErrorMsgShown;
    },
    setIsDeleteOrgModalShown: (state, action) => {
      state.isDeleteOrgModalShown = !state.isDeleteOrgModalShown;
      state.selectedData = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setIsAddCompanyModalShown: (state, action) => {
      state.isAddCompanyModalShown = !state.isAddCompanyModalShown;
      state.isModalsBgShown = !state.isModalsBgShown;
      state.selectedData = action.payload;
    },
    setIsDeleteCompanyModalShown: (state, action) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isDeleteCompanyModalShown = !state.isDeleteCompanyModalShown;
      state.selectedData = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setIsDepartmentsModalShown: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isDepartmentsModalShown = !state.isDepartmentsModalShown;
    },
    setIsAddUserModalShown: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isAddUserModalShown = !state.isAddUserModalShown;
    },
    setIsEditUserModalShown: (state, action) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isEditUserModalShown = !state.isEditUserModalShown;
      state.selectedData = action.payload;
    },
    setIsDeleteUserModalShown: (state, action) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isDeleteUserModalShown = !state.isDeleteUserModalShown;
      state.selectedData = action.payload;
    },
    setIsOrgShown: (state) => {
      state.isOrgShown = !state.isOrgShown;
    },
    setIsAddManager: (state, action) => {
      state.isModalsBgShown = action.payload;
      state.isAddManager = action.payload;
    },
    setAddressData: (state, action) => {
      state.addressData = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCurrentPageDepartmentsModal: (state, action) => {
      state.currentPageDepartmentsModal = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setIsCompanyDepartmentCreated: (state, action) => {
      state.isCompanyDepartmentCreated = action.payload;
    },
  },
});

export const {
  setIsCompanyDepartmentCreated,
  setSelectedCompany,
  setIsModalsBgShown,
  setCurrentPageDepartmentsModal,
  setCurrentPage,
  setAddressData,
  setIsAddManager,
  setIsOrgShown,
  setIsDeleteUserModalShown,
  setIsEditUserModalShown,
  setIsAddUserModalShown,
  setIsDepartmentsModalShown,
  setSelectedData,
  setIsDeleteCompanyModalShown,
  setIsAddCompanyModalShown,
  setIsOrganizationsClicked,
  setIsUsersClicked,
  setIsAddOrgModalShown,
  setIsSuccessMsgShown,
  setIsErrorMsgShown,
  setIsDeleteOrgModalShown,
  setIsEditMode,
} = globalSlice.actions;
export default globalSlice.reducer;
