import classes from "./Organizations.module.css";
import PlusIcon from "../../images/organizations/plusIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAddCompanyModalShown,
  setIsAddOrgModalShown,
  setIsCompanyDepartmentCreated,
  setIsDeleteCompanyModalShown,
  setIsDeleteOrgModalShown,
  setIsDepartmentsModalShown,
  setIsModalsBgShown,
  setIsOrgShown,
  setSelectedCompany,
  setSelectedData,
} from "../../redux/globalFnSlice";
import { useEffect, useState } from "react";
import { resetCreateDepartmentStatus } from "../../redux/department/createDepartment";
import { OrganizationsTableElement } from "../UI/organizationsTableElement/OrganizationsTableElement";
import { fetchAllDepartments } from "../../redux/department/getAllDeaprtments";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import { resetDeleteDepartmentStatus } from "../../redux/department/deleteDepartment";
import { useStatusEffect } from "../../helperFunctions/customHooks/useStatusEffect";
import { resetUpdateDepartmentStatus } from "../../redux/department/updateDepartment";
import { resetCreateCompanyState } from "../../redux/company/createCompany";
import { fetchCompanies } from "../../redux/company/getCompanies";
import { resetUpdateCompanyStatus } from "../../redux/company/updateCompany";
import { resetDeleteCompanyStatus } from "../../redux/company/deleteCompany";
import { setSearchQuery } from "../../redux/search/search";
import { Pagination } from "../UI/pagination/Pagination";
import { CompaniesTableElements } from "../UI/companiesTableElements/CompaniesTableElements";
import translations from "../../en.json";
import { setCurrentPage } from "../../redux/globalFnSlice";

export function Organizations() {
  const dispatch = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);

  // Search query state.
  const searchQuery = useSelector((state) => state.search.query);
  const { isOrgShown, currentPage } = useSelector((state) => state.global);

  // Departments states.
  const departmentsState = useSelector((state) => state.getAllDepartments);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);

  // Company states.
  const companiesState = useSelector((state) => state.getCompanies);
  const createCompanyState = useSelector((state) => state.createCompany);
  const updateCompanyState = useSelector((state) => state.updateCompany);
  const deleteCompanyState = useSelector((state) => state.deleteCompany);

  // Getting total pages count for departments.
  const departmentTotalPagesCount = Math.ceil(
    departmentsState.total_count / 10
  );
  const filteredDepartmentPages = Math.ceil(
    departmentsState.filtered_count / 10
  );

  // Getting total pages count for compaies.
  const companyTotalPagesCount = Math.ceil(
    companiesState.companies.total_count / 10
  );
  const filteredCompanyPages = Math.ceil(
    companiesState.companies.filtered_count / 10
  );

  // When a company or department is successfully created:
  // - Set the current page to the latest page count for the respective entity.
  // - Clear any existing search query to refresh the displayed results.
  useEffect(() => {
    if (createCompanyState.status === "success") {
      dispatch(setCurrentPage(companyTotalPagesCount));
      dispatch(setSearchQuery(""));
    } else if (createDepartmentState.status === "success") {
      dispatch(setCurrentPage(departmentTotalPagesCount));
      dispatch(setSearchQuery(""));
    }
  }, [createCompanyState.status, createDepartmentState.status]);

  // Initial fetch and refetch data when search query or page changes.
  useEffect(() => {
    if (isOrgShown) {
      dispatch(
        fetchAllDepartments({
          department_name: searchQuery,
          page: currentPage,
        })
      );
    } else if (!isOrgShown) {
      dispatch(
        fetchCompanies({ page: currentPage, company_name: searchQuery })
      );
    }
    setIsFiltered(true);
  }, [dispatch, searchQuery, currentPage, isOrgShown]);

  // Show the departments modal again based on department state changes.
  useEffect(() => {
    if (!isOrgShown) {
      if (createDepartmentState.status === "success") {
        dispatch(setIsCompanyDepartmentCreated(true));
        setTimeout(() => {
          dispatch(setIsDepartmentsModalShown());
        }, 2000);
      } else if (
        deleteDepartmentState.status === "success" ||
        updateDepartmentState.status === "success"
      ) {
        setTimeout(() => {
          dispatch(setIsDepartmentsModalShown());
        }, 2000);
      } else if (createDepartmentState.status === "error") {
        dispatch(setIsModalsBgShown(false));
        setTimeout(() => {
          dispatch(setIsDepartmentsModalShown());
        }, 2000);
      }
    }
  }, [
    createDepartmentState.status,
    deleteDepartmentState.status,
    updateDepartmentState.status,
  ]);

  // Variable for translations.
  const orgPageText = translations.organizations;

  // If total_count is a multiple of 10, set page to refetch with +1.
  const whichPageDepartments =
    departmentsState.total_count % 10 === 0
      ? departmentTotalPagesCount + 1
      : departmentTotalPagesCount;

  // Functions for Departments for showing notification messages based on the status.
  useStatusEffect({
    status: createDepartmentState.status,
    successMessage: orgPageText.createOrgMsg,
    errorMessage: createDepartmentState.error,
    onSuccess: setIsAddOrgModalShown,
    onError: setIsAddOrgModalShown,
    resetStatus: resetCreateDepartmentStatus,
    reFetch: isOrgShown ? fetchAllDepartments : fetchCompanies,
    onPage: isOrgShown ? whichPageDepartments : currentPage,
    functionName: "createOrg",
  });

  useStatusEffect({
    status: deleteDepartmentState.status,
    successMessage: orgPageText.deleteorgMsg,
    errorMessage: deleteDepartmentState.error,
    onSuccess: setIsDeleteOrgModalShown,
    onError: setIsDeleteOrgModalShown,
    resetStatus: resetDeleteDepartmentStatus,
    reFetch: fetchAllDepartments,
    onPage: currentPage,
    functionName: "deleteOrg",
  });

  useStatusEffect({
    status: updateDepartmentState.status,
    successMessage: orgPageText.updatedOrgMsg,
    errorMessage: updateDepartmentState.error,
    onSuccess: setIsAddOrgModalShown,
    onError: setIsAddOrgModalShown,
    resetStatus: resetUpdateDepartmentStatus,
    reFetch: fetchAllDepartments,
    onPage: currentPage,
    functionName: "updateOrg",
  });

  // If deleted the last result on page for departments, refetch the results with currentPage - 1.
  useEffect(() => {
    if (departmentsState.status === "error" && currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      dispatch(fetchAllDepartments({ page: currentPage - 1 }));
    }
  }, [departmentsState.status]);

  // If total_count is a multiple of 10, set page to refetch with +1.
  const whichPageCompanies =
    companiesState.companies.total_count % 10 === 0
      ? companyTotalPagesCount + 1
      : companyTotalPagesCount;

  // Functions for Companies for showing notification messages based on the status.
  useStatusEffect({
    status: createCompanyState.status,
    successMessage: orgPageText.createdCompanyMsg,
    errorMessage: createCompanyState.error,
    onSuccess: setIsAddCompanyModalShown,
    onError: setIsAddCompanyModalShown,
    resetStatus: resetCreateCompanyState,
    reFetch: fetchCompanies,
    onPage: whichPageCompanies,
  });

  useStatusEffect({
    status: updateCompanyState.status,
    successMessage: orgPageText.updatedCompanyMsg,
    errorMessage: updateCompanyState.error,
    onSuccess: setIsAddCompanyModalShown,
    onError: setIsAddCompanyModalShown,
    resetStatus: resetUpdateCompanyStatus,
    reFetch: fetchCompanies,
    onPage: currentPage,
  });

  useStatusEffect({
    status: deleteCompanyState.status,
    successMessage: orgPageText.deletedCompanyMsg,
    errorMessage: deleteCompanyState.error,
    onSuccess: setIsDeleteCompanyModalShown,
    onError: setIsDeleteCompanyModalShown,
    resetStatus: resetDeleteCompanyStatus,
    reFetch: fetchCompanies,
    onPage: currentPage,
  });

  // If deleted the last result on page for companies, refetch the results with currentPage - 1.
  useEffect(() => {
    if (companiesState.status === "error" && currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      dispatch(fetchCompanies({ page: currentPage - 1 }));
    }
  }, [companiesState.status]);

  // Function for toggling between Companies and Departments tab.
  function toggleTabs() {
    dispatch(setSelectedCompany(""));
    dispatch(setIsOrgShown());
    dispatch(setSearchQuery(""));
    setIsFiltered(false);
    dispatch(setCurrentPage(1));
  }

  // Variable to determine if the status is loading based on which component is shown.
  const isStatusLoading =
    (departmentsState.status === "loading" && isOrgShown) ||
    (companiesState.status === "loading" && !isOrgShown);

  // Variable to determine if the status has an error based on which component is shown.
  const isStatusError =
    (departmentsState.status === "error" && isOrgShown) ||
    (companiesState.status === "error" && !isOrgShown);

  function createDepartmentBtnFunctions() {
    dispatch(setIsModalsBgShown(true));
    dispatch(setIsAddOrgModalShown());
    dispatch(setSelectedData(""));
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.toggleButtons}>
            <button
              className={isOrgShown ? classes.active : classes.toggleOrgBtn}
              onClick={toggleTabs}
              disabled={isOrgShown}
            >
              {orgPageText.departmentsBtn}
            </button>
            <button
              className={
                isOrgShown ? classes.toggleCompaniesBtn : classes.active
              }
              onClick={toggleTabs}
              disabled={!isOrgShown}
            >
              {orgPageText.companiesBtn}
            </button>
          </div>
          <div className={classes.buttons}>
            <button
              className={isOrgShown ? classes.orgBtn : classes.companyBtn}
              onClick={() =>
                isOrgShown
                  ? createDepartmentBtnFunctions()
                  : dispatch(setIsAddCompanyModalShown())
              }
            >
              <img src={PlusIcon} alt={orgPageText.plusIconAlt} />
              {isOrgShown
                ? orgPageText.createDepartmentBtn
                : orgPageText.createCompanyBtn}
            </button>
          </div>
        </div>
      </div>
      {isOrgShown && (
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div>{orgPageText.departmentsTable.name}</div>
            <div>{orgPageText.departmentsTable.address}</div>
            <div>{orgPageText.departmentsTable.phone}</div>
            <div>{orgPageText.departmentsTable.edit}</div>
            <div>{orgPageText.departmentsTable.delete}</div>
          </div>
          {!isStatusLoading &&
            !isStatusError &&
            departmentsState.all_departments.map((department) => (
              <OrganizationsTableElement
                data={department}
                key={department.d_id}
                address={department.d_address}
                organization={department.d_name}
                phone={department.d_phonenumber}
              />
            ))}
        </div>
      )}
      {!isOrgShown && (
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div>{orgPageText.companiesTable.name}</div>
            <div>{orgPageText.companiesTable.edit}</div>
            <div>{orgPageText.companiesTable.delete}</div>
          </div>
          {!isStatusLoading &&
            !isStatusError &&
            companiesState.companies.companies &&
            companiesState.companies.companies.map((company) => (
              <CompaniesTableElements
                key={company.c_id}
                name={company.c_name}
                data={company}
              />
            ))}
        </div>
      )}
      {isStatusLoading && (
        <div className={classes.notificationsDiv}>
          <LoadingSpinner black={true} />
        </div>
      )}
      {!isStatusLoading && isStatusError && (
        <div className={classes.notificationsDiv}>
          <span className={classes.errorMsg}>
            {isOrgShown ? departmentsState.error : companiesState.error}
          </span>
        </div>
      )}
      {(departmentsState.all_departments.length !== 0 ||
        companiesState.companies.length !== 0) &&
        !isStatusError &&
        !isStatusLoading && (
          <Pagination
            currentPage={currentPage}
            totalPages={
              isOrgShown ? departmentTotalPagesCount : companyTotalPagesCount
            }
            filteredPages={
              isOrgShown ? filteredDepartmentPages : filteredCompanyPages
            }
            isFiltered={isFiltered}
          />
        )}
    </div>
  );
}
