import classes from "./DeleteOrganization.module.css";
import closeIcon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDeleteOrgModalShown,
  setIsDepartmentsModalShown,
  setIsModalsBgShown,
} from "../../../redux/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useRef } from "react";
import { deleteDepartment } from "../../../redux/department/deleteDepartment";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import translations from "../../../en.json";
import useEnterKey from "../../../helperFunctions/customHooks/useEnterKeySubmit";

export function DeleteOrganization() {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const { selectedData, isOrgShown } = useSelector((state) => state.global);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);

  function toggleModal() {
    dispatch(setIsModalsBgShown(false));
    dispatch(setIsDeleteOrgModalShown());
    if (!isOrgShown) {
      dispatch(setIsDepartmentsModalShown());
    }
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = deleteDepartmentState.status === "loading";

  const modalText = translations.deleteOrganizationModal;

  function handleSubmit() {
    dispatch(deleteDepartment({ department_id: selectedData.d_id }));
  }

  // Function for submitting the form when presing the enter key on the keyboard.
  useEnterKey(handleSubmit);

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h4>{modalText.headerText}</h4>
        <span onClick={toggleModal}>
          <img src={closeIcon} alt={modalText.closeModalIconAlt} />
        </span>
      </div>
      <p>
        {modalText.confirmationMessage}{" "}
        <span className={classes.orgName}>{selectedData.d_name} ?</span>
      </p>
      <p>{modalText.warningMessage}</p>
      <div className={classes.buttons}>
        <button className={classes.cancelBtn} onClick={toggleModal}>
          {modalText.cancelBtn}
        </button>
        <button
          className={classes.confirmBtn}
          disabled={isStatusLoading}
          onClick={handleSubmit}
        >
          {isStatusLoading ? <LoadingSpinner /> : modalText.confirmBtn}
        </button>
      </div>
    </div>
  );
}
