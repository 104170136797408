import { useDispatch, useSelector } from "react-redux";
import classes from "./Users.module.css";
import {
  setCurrentPage,
  setIsAddManager,
  setIsAddUserModalShown,
  setIsDeleteUserModalShown,
  setIsEditUserModalShown,
} from "../../redux/globalFnSlice";
import { UsersTableElements } from "../UI/usersTableElements/UsersTableElements";
import { useEffect, useState } from "react";
import { fetchUsers } from "../../redux/users/getUsers";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import { ErrorMsg } from "../errorMsg/ErrorMsg";
import { useStatusEffect } from "../../helperFunctions/customHooks/useStatusEffect";
import { resetUserDeleteStatus } from "../../redux/users/deleteUser";
import { resetUpdateUserStatus } from "../../redux/users/updateUser";
import { resetCreateManagerState } from "../../redux/managers/createManager";
import { fetchCompanies } from "../../redux/company/getCompanies";
import { fetchAllDepartments } from "../../redux/department/getAllDeaprtments";
import { Pagination } from "../UI/pagination/Pagination";
import { setSearchQuery } from "../../redux/search/search";
import translations from "../../en.json";

export function Users() {
  // Variables for redux states.
  const { users, status, error, total_count, filtered_count } = useSelector(
    (state) => state.getUsers
  );
  const deleteUserState = useSelector((state) => state.deleteUser);
  const updateUserState = useSelector((state) => state.updateUser);
  const { isAddUserModalShown, currentPage } = useSelector(
    (state) => state.global
  );
  const createManagerState = useSelector((state) => state.createManager);
  const searchQuery = useSelector((state) => state.search.query);
  const companiesState = useSelector((state) => state.getCompanies);
  const departmentsState = useSelector((state) => state.getAllDepartments);

  const dispatch = useDispatch();
  const itemsPerPage = 10;

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  // Getting total pages count for compaies.
  const totalPagesCount = Math.ceil(total_count / 10);
  const filteredTotalPagesCount = Math.ceil(filtered_count / 10);

  // Variable for translations.
  const usersPage = translations.usersPage;

  // Function for filtering by Role.
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setIsFiltered(true);
    dispatch(setCurrentPage(1));
  };

  // Function for filtering by Department.
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setIsFiltered(true);
    dispatch(setCurrentPage(1));
  };

  // Function for filtering by Company.
  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    setIsFiltered(true);
    dispatch(setCurrentPage(1));
  };

  // Function for resetting the filters.
  const resetFilters = () => {
    setSelectedRole("");
    setSelectedDepartment("");
    setSelectedCompany("");
    dispatch(setSearchQuery(""));
    dispatch(setCurrentPage(1));
  };

  // Reset the filters if create manager status is success.
  useEffect(() => {
    if (createManagerState.status === "success") {
      resetFilters();
    }
  }, [createManagerState.status]);

  // Initial fetch of data.
  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchAllDepartments());
  }, [dispatch]);

  // Fetch users based on the current page and filters.
  useEffect(() => {
    dispatch(
      fetchUsers({
        page: currentPage,
        per_page: itemsPerPage,
        department_name: selectedDepartment,
        user_type: selectedRole,
        driver_name: searchQuery,
        company_name: selectedCompany,
      })
    );

    setIsFiltered(true);
  }, [
    dispatch,
    currentPage,
    selectedDepartment,
    selectedRole,
    searchQuery,
    selectedCompany,
    itemsPerPage,
  ]);

  // Populate filter options when users data is successfully fetched.
  useEffect(() => {
    if (
      status === "success" &&
      departmentsState.department_names &&
      companiesState.companies &&
      departmentsState.status === "success" &&
      companiesState.status === "success"
    ) {
      const uniqueDepartments = Array.from(
        new Set(
          departmentsState.department_names.map(
            (department) => department.d_name
          )
        )
      );
      setDepartmentOptions(uniqueDepartments);

      const uniqueCompanies = Array.from(
        new Set(
          companiesState.companies.company_names.map(
            (company) => company.c_name
          )
        )
      );
      setCompanyOptions(uniqueCompanies);
    }
  }, [status, departmentsState, companiesState]);

  // Which success message to be shown based on the isAddUserModalShown.
  const successMsg = isAddUserModalShown
    ? usersPage.createdDriverMsg
    : usersPage.createdManagerMsg;

  // If total_count is a multiple of 10, set page to refetch with +1.
  const whichPage =
    total_count % 10 === 0 ? totalPagesCount + 1 : totalPagesCount;

  // Functions for Managers and Drivers for showing notification messages based on the status.
  useStatusEffect({
    status: deleteUserState.status,
    successMessage: usersPage.deletedUserMsg,
    errorMessage: deleteUserState.error,
    onSuccess: setIsDeleteUserModalShown,
    onError: setIsDeleteUserModalShown,
    resetStatus: resetUserDeleteStatus,
    reFetch: fetchUsers,
    onPage: currentPage,
  });

  useStatusEffect({
    status: updateUserState.status,
    successMessage: usersPage.updatedUserMsg,
    errorMessage: updateUserState.error,
    onSuccess: setIsEditUserModalShown,
    onError: setIsEditUserModalShown,
    resetStatus: resetUpdateUserStatus,
    reFetch: fetchUsers,
    onPage: currentPage,
  });

  useStatusEffect({
    status: createManagerState.status,
    successMessage: successMsg,
    errorMessage: createManagerState.error,
    onSuccess: isAddUserModalShown ? setIsAddUserModalShown : setIsAddManager,
    onError: isAddUserModalShown ? setIsAddUserModalShown : setIsAddManager,
    resetStatus: resetCreateManagerState,
    reFetch: fetchUsers,
    functionName: isAddUserModalShown ? "driver" : "manager",
    onPage: whichPage,
  });

  // If deleted the last result on page refetch the results with currentPage - 1.
  useEffect(() => {
    if (status === "error" && currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      dispatch(fetchUsers({ page: currentPage - 1 }));
    }
  }, [status, currentPage]);

  // Variable checking if the status is loading.
  const isStatusLoading = status === "loading";

  // Variable for checking if the button should be disabled or not.
  const isBtnDisabled =
    !selectedRole &&
    !selectedDepartment &&
    !selectedCompany &&
    searchQuery === "";

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <h2>{usersPage.headerText}</h2>
          <div className={classes.btns}>
            <button
              className={classes.addUserBtn}
              onClick={() => dispatch(setIsAddUserModalShown())}
            >
              {usersPage.addDriverBtn}
            </button>
            <button
              className={classes.addManagerBtn}
              onClick={() => dispatch(setIsAddManager(true))}
            >
              {usersPage.addManagerBtn}
            </button>
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <div className={classes.filters}>
          <p>{usersPage.filters.filterBy}</p>
          <div className={classes.filter}>
            <label htmlFor="role">{usersPage.filters.role}</label>
            <select
              name="role"
              id="role"
              className={classes.role}
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">{usersPage.filters.roleOptions.all}</option>
              <option value="Manager">
                {usersPage.filters.roleOptions.managers}
              </option>
              <option value="Driver">
                {usersPage.filters.roleOptions.drivers}
              </option>
            </select>
          </div>
          <div className={classes.filter}>
            <label htmlFor="department">{usersPage.filters.department}</label>
            <select
              name="department"
              id="department"
              className={classes.department}
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">{usersPage.filters.allDepartments}</option>
              {departmentOptions.map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.filter}>
            <label htmlFor="company">{usersPage.filters.companies}</label>
            <select
              name="company"
              id="company"
              className={classes.company}
              value={selectedCompany}
              onChange={handleCompanyChange}
            >
              <option value="">{usersPage.filters.allCompanies}</option>
              {companyOptions.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <button
            className={classes.resetBtn}
            onClick={resetFilters}
            disabled={isBtnDisabled}
          >
            {usersPage.filters.resetBtn}
          </button>
        </div>
        <div className={classes.tableHeader}>
          <div>{usersPage.table.id}</div>
          <div>{usersPage.table.firstname}</div>
          <div>{usersPage.table.lastname}</div>
          <div>{usersPage.table.email}</div>
          <div>{usersPage.table.companyName}</div>
          <div>{usersPage.table.department}</div>
          <div>{usersPage.table.role}</div>
          <div>{usersPage.table.edit}</div>
          <div>{usersPage.table.delete}</div>
        </div>
        {isStatusLoading && (
          <div className={classes.notificationsDiv}>
            <LoadingSpinner black={true} />
          </div>
        )}

        {!isStatusLoading &&
          status !== "error" &&
          users.map((user) => (
            <UsersTableElements
              key={user.u_id}
              id={user.u_id}
              firstname={user.u_firstname}
              lastname={user.u_lastname}
              email={user.u_email}
              company_name={user.c_name}
              department={user.d_name}
              role={user.u_utid}
              data={user}
            />
          ))}
        {!isStatusLoading && status === "error" && users.length === 0 && (
          <div className={classes.notificationsDiv}>
            <ErrorMsg msg={usersPage.errorMsg} />
          </div>
        )}
      </div>
      {!isStatusLoading && users.length > 0 && status !== "error" && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPagesCount}
          filteredPages={filteredTotalPagesCount}
          isFiltered={isFiltered}
        />
      )}
    </div>
  );
}
