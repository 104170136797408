import classes from "./CompaniesTableElements.module.css";
import editIcon from "../../../images/editIcon.svg";
import deleteIcon from "../../../images/deleteIcon.svg";
import { useDispatch } from "react-redux";
import {
  setIsAddCompanyModalShown,
  setIsDeleteCompanyModalShown,
  setIsDepartmentsModalShown,
  setIsEditMode,
  setSelectedCompany,
} from "../../../redux/globalFnSlice";
import translations from "../../../en.json";

export function CompaniesTableElements({ name, data }) {
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <div
        className={classes.name}
        onClick={() => {
          dispatch(setSelectedCompany(data));
          dispatch(setIsDepartmentsModalShown());
        }}
      >
        {name}
      </div>
      <div>
        <img
          src={editIcon}
          alt={translations.uiTableElements.editIconAlt}
          onClick={() => {
            dispatch(setIsAddCompanyModalShown(data));
            dispatch(setIsEditMode(true));
          }}
        />
      </div>
      <div>
        <img
          src={deleteIcon}
          alt={translations.uiTableElements.deleteIconAlt}
          onClick={() => dispatch(setIsDeleteCompanyModalShown(data))}
        />
      </div>
    </div>
  );
}
