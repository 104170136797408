import classes from "./AddManager.module.css";
import xIcon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  setIsAddManager,
  setIsAddUserModalShown,
} from "../../../redux/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { createManager } from "../../../redux/managers/createManager";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import SearchableDropdown from "../../searchableDropdown/SearchableDropdown";
import { fetchCompanies } from "../../../redux/company/getCompanies";
import {
  fetchDepartments,
  resetCompanyDepartments,
} from "../../../redux/department/getDepartments";
import translations from "../../../en.json";
import useEnterKey from "../../../helperFunctions/customHooks/useEnterKeySubmit";

export function AddManager() {
  const dispatch = useDispatch();
  const modalRef = useRef();

  const [companyId, setCompanyId] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    u_did: "",
    is_driver: "",
  });

  // Redux states.
  const createManagerState = useSelector((state) => state.createManager);
  const departmentsState = useSelector((state) => state.getDepartments);
  const companiesState = useSelector((state) => state.getCompanies);
  const { isAddUserModalShown } = useSelector((state) => state.global);

  // Initial fetch of companies to append to dropdown element.
  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    if (isAddUserModalShown) {
      setFormData((prevData) => ({
        ...prevData,
        is_driver: 1,
      }));
    }
  }, [isAddUserModalShown]);

  // Function for closing the modal and reseting the company departments state.
  function handleCloseModal() {
    if (isAddUserModalShown) {
      dispatch(setIsAddUserModalShown(false));
    }
    dispatch(setIsAddManager(false));
    dispatch(resetCompanyDepartments());
  }

  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  // Handle input change function
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure only numeric characters for phone number input
    if (name === "phone_number" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function handleDropdownSelect({ target }) {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  // Function for submitting the form.
  function handleSubmit(e) {
    e.preventDefault();
    dispatch(createManager(formData));
  }

  // Function for submitting the form when presing the enter key on the keyboard.
  useEnterKey(handleSubmit);

  // If company id changes refetch the company departments.
  useEffect(() => {
    if (companyId) {
      dispatch(fetchDepartments({ company_id: companyId }));
    }
  }, [companyId]);

  const isStatusLoading = createManagerState.status === "loading";
  const isBtnDisabled =
    isStatusLoading ||
    formData.email === "" ||
    formData.password === "" ||
    formData.confirm_password === "" ||
    formData.first_name === "" ||
    formData.last_name === "" ||
    formData.phone_number === "" ||
    formData.u_did === "" ||
    (isAddUserModalShown && formData.is_driver === "");

  const modalText = translations.addManagerModal;

  const headerText = isAddUserModalShown
    ? modalText.headerTextDriver
    : modalText.headerTextManager;

  // Prevent space from being added.
  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <div className={classes.container} ref={modalRef}>
      <header className={classes.header}>
        <h3>{headerText}</h3>
        <span>
          <img
            src={xIcon}
            alt={modalText.closeModalIconAlt}
            onClick={handleCloseModal}
          />
        </span>
      </header>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.elements}>
          <div className={classes.formElement}>
            <label htmlFor="firstName">{modalText.form.firstname}</label>
            <input
              type="text"
              name="first_name"
              id="firstName"
              value={formData.first_name}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.formElement}>
            <label htmlFor="lastName">{modalText.form.lastname}</label>
            <input
              type="text"
              name="last_name"
              id="lastName"
              value={formData.last_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={classes.elements}>
          <div className={classes.formElement}>
            <label htmlFor="email">{modalText.form.email}</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className={classes.formElement}>
            <label htmlFor="phone">{modalText.form.phone}</label>
            <input
              type="tel"
              name="phone_number"
              id="phone"
              pattern="[0-9]*"
              value={formData.phone_number}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={classes.elements}>
          <div className={classes.formElement}>
            <label htmlFor="password">{modalText.form.password}</label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.formElement}>
            <label htmlFor="confirmPassword">
              {modalText.form.confirmPassword}
            </label>
            <input
              type="password"
              name="confirm_password"
              id="confirmPassword"
              value={formData.confirm_password}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={classes.elements}>
          <div className={classes.formElement}>
            <SearchableDropdown
              idForLabel={modalText.form.selectCompany}
              options={
                companiesState.companies.company_names &&
                companiesState.companies.company_names.map((company) => ({
                  value: company.c_id,
                  label: company.c_name,
                  keyId: company.c_id,
                }))
              }
              placeholder={modalText.form.selectName}
              onSelect={(value) => setCompanyId(value)}
              value={companyId}
            />
          </div>
          <div className={classes.formElement}>
            <SearchableDropdown
              idForLabel={modalText.form.selectDepartment}
              options={
                departmentsState.department_names &&
                departmentsState.department_names.map((department) => ({
                  value: department.d_id,
                  label: department.d_name,
                  keyId: department.d_id,
                }))
              }
              placeholder={modalText.form.selectName}
              onSelect={(value) =>
                handleDropdownSelect({ target: { name: "u_did", value } })
              }
              value={formData.u_did}
            />
          </div>
        </div>
        <button type="submit" disabled={isBtnDisabled}>
          {isStatusLoading ? <LoadingSpinner /> : modalText.saveBtn}
        </button>
      </form>
    </div>
  );
}
