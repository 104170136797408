import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../redux/register/register";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import classes from "./Register.module.css";
import { resetRegisterState } from "../../redux/register/register";
import translations from "../../en.json";

export function Register() {
  const dispatch = useDispatch();
  const registerSelector = useSelector((state) => state.register);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [token, setToken] = useState("superadminmanager");

  // Variable for translations.
  const registerPage = translations.registerPage;

  useEffect(() => {
    dispatch(resetRegisterState());
  }, []);

  useEffect(() => {
    if (registerSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetRegisterState());
      }, 2000);
    } else if (isStatusSuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }
  }, [registerSelector.status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("token", token);
    dispatch(registerUser(Object.fromEntries(formData)));
  };

  const isStatusSuccess = registerSelector.status === "success";
  const isStatusLoading = registerSelector.status === "loading";

  return (
    <section className={classes.registerContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt={registerPage.iconAlts.logoAlt} />
      </div>
      <div className={classes.formBg}>
        <img
          className={classes.background}
          src={Background}
          alt={registerPage.iconAlts.formBgAlt}
        />
        <form className={classes.registerForm} onSubmit={handleSubmit}>
          <h2>{registerPage.headerText}</h2>
          <div className={classes.inputs}>
            <div className={classes.input}>
              <label htmlFor="email">{registerPage.form.email}</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={classes.input}>
              <label htmlFor="password">{registerPage.form.password}</label>
              <input type="password" name="password" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="confirm_password">
                {registerPage.form.confirmPassword}
              </label>
              <input type="password" name="confirm_password" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="first_name">{registerPage.form.firstname}</label>
              <input type="text" name="first_name" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="last_name">{registerPage.form.lastname}</label>
              <input type="text" name="last_name" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="phone_number">{registerPage.form.phone}</label>
              <input
                type="tel"
                name="phone_number"
                value={phonenumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setPhonenumber(value);
                }}
                required
              />
            </div>
            <button
              className={classes.registerBtn}
              type="submit"
              disabled={isStatusLoading}
            >
              {isStatusLoading ? (
                <LoadingSpinner black={true} />
              ) : (
                registerPage.registerBtn
              )}
            </button>
          </div>
          <div className={classes.notifications}>
            {registerSelector.status === "error" && (
              <ErrorMsg msg={registerSelector.error} />
            )}
            {isStatusSuccess && <p>{registerPage.successMsg}</p>}
          </div>
        </form>
      </div>
    </section>
  );
}
