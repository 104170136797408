import classes from "./Pagination.module.css";
import {
  setCurrentPage,
  setCurrentPageDepartmentsModal,
} from "../../../redux/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";

export function Pagination({
  totalPages,
  filteredPages,
  isFiltered,
  currentPage,
  currentPageDepartmentsModal,
}) {
  const dispatch = useDispatch();
  const { isDepartmentsModalShown } = useSelector((state) => state.global);

  // Departments modal next page function.
  const handleNextPageDepartmentsModal = () => {
    dispatch(
      setCurrentPageDepartmentsModal(
        Math.min(currentPageDepartmentsModal + 1, totalDisplayedPages)
      )
    );
  };

  // Global next page function.
  const handleNextPage = () => {
    dispatch(setCurrentPage(Math.min(currentPage + 1, totalDisplayedPages)));
  };
  // Departments modal previous page function.
  const handlePrevPageDepartmentsModal = () => {
    dispatch(
      setCurrentPageDepartmentsModal(
        Math.max(currentPageDepartmentsModal - 1, 1)
      )
    );
  };
  // Global previous page function.
  const handlePrevPage = () => {
    dispatch(setCurrentPage(Math.max(currentPage - 1, 1)));
  };

  // Departments modal page click function.
  const handlePageClickDepartmentsModal = (page) => {
    dispatch(setCurrentPageDepartmentsModal(page));
  };

  // Global page click function.
  const handlePageClick = (page) => {
    dispatch(setCurrentPage(page));
  };

  // Determine the function to handle the next page click based on whether the departments modal is shown.
  const nextPageClickFn = isDepartmentsModalShown
    ? handleNextPageDepartmentsModal
    : handleNextPage;

  // Determine the function to handle the previous page click based on whether the departments modal is shown.
  const prevPageClickFn = isDepartmentsModalShown
    ? handlePrevPageDepartmentsModal
    : handlePrevPage;

  // Determine the function to handle the page click based on whether the departments modal is shown.
  const pageClickFn = (page) => {
    if (isDepartmentsModalShown) {
      handlePageClickDepartmentsModal(page);
    } else {
      handlePageClick(page);
    }
  };

  // Set the current page based on whether the departments modal is shown.
  const whichCurrentPage = isDepartmentsModalShown
    ? currentPageDepartmentsModal
    : currentPage;

  // Set the total number of displayed pages based on filtering.
  const totalDisplayedPages = isFiltered ? filteredPages : totalPages;

  // Function for rendering page buttons.
  const renderPageButton = (page) => (
    <button
      key={page}
      className={
        whichCurrentPage === page ? classes.activePage : classes.firstPage
      }
      onClick={() => pageClickFn(page)}
    >
      {page}
    </button>
  );

  const renderPages = () => {
    if (totalDisplayedPages <= 4) {
      // Function for displaying all pages if 4 or fewer.
      return Array.from({ length: totalDisplayedPages }, (_, i) =>
        renderPageButton(i + 1)
      );
    } else {
      // Function for displaying the first 4 pages and the last page with three dots before the last page.
      return (
        <>
          {Array.from({ length: 4 }, (_, i) => renderPageButton(i + 1))}
          {whichCurrentPage > 4 && whichCurrentPage !== totalDisplayedPages && (
            <button className={classes.activePage}>{whichCurrentPage}</button>
          )}
          {whichCurrentPage < totalDisplayedPages - 1 &&
            totalDisplayedPages > 5 && <p>...</p>}
          {whichCurrentPage === totalDisplayedPages &&
            totalDisplayedPages > 6 && <p>...</p>}
          {whichCurrentPage === totalDisplayedPages &&
            totalDisplayedPages > 5 &&
            renderPageButton(whichCurrentPage - 1)}
          {renderPageButton(totalDisplayedPages)}
        </>
      );
    }
  };

  return (
    <div className={classes.pagination}>
      <button
        onClick={prevPageClickFn}
        disabled={whichCurrentPage === 1}
        className={classes.prevBtn}
      >
        {"<"}
      </button>
      {renderPages()}
      <button
        onClick={nextPageClickFn}
        disabled={whichCurrentPage === totalDisplayedPages}
        className={classes.nextBtn}
      >
        {">"}
      </button>
    </div>
  );
}
