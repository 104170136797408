import { useEffect } from "react";

const useEnterKey = (callback) => {
  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === "Enter") {
        callback(event);
      }
    };

    window.addEventListener("keydown", handleEnterPress);

    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [callback]);
};

export default useEnterKey;
