export const initialState = {
  isOrganizationsClicked: true,
  isUsersClicked: false,
  isModalsBgShown: false,
  isAddOrgModalShown: false,
  isSuccessMsgShown: false,
  successMsg: "",
  isErrorMsgShown: false,
  errorMsg: "",
  isDeleteOrgModalShown: false,
  selectedData: null,
  selectedId: null,
  isEditMode: false,
  isAddCompanyModalShown: false,
  isDeleteCompanyModalShown: false,
  isDepartmentsModalShown: false,
  isAddUserModalShown: false,
  isEditUserModalShown: false,
  isDeleteUserModalShown: false,
  isOrgShown: true,
  isAddManager: false,
  addressData: {},
  currentPage: 1,
  currentPageDepartmentsModal: 1,
  selectedCompany: null,
  isCompanyDepartmentCreated: false,
};
